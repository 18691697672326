import type { DataTableHeader } from "vuetify/types";

import { Component, Vue, Ref } from "vue-property-decorator";
import axios, { CancelToken, CancelTokenSource } from "axios";
import debounce from "lodash.debounce";
import {
  formatMoney,
  hashStringToColor,
  numberToFixed,
  getSymbolCurrency,
  forOwn,
  getFullName,
} from "@helpers";
import { downloadCSV } from "@/lib/csv";
import {
  formatDatePickerVal,
  parseDatePickerVal,
  periodRangeEndTimestamp,
  periodRangeStartTimestamp,
  getTimezoneHoursInSeconds,
  getPeriodDate,
} from "@/lib/date";
import {
  userModule,
  profileModule,
  teamModule,
  walletModule,
} from "@store/namespaces";
import { Currency } from "@/types/currency";
import {
  StatisticByType,
  StatisticByStatus,
  StatisticGroupField,
} from "@/types/statistic";
import { downloadXLSX } from "@/lib/xlsx";
import { UserActions, UserGetters } from "@store/modules/user/types";
import { UserWebPreferencesKey } from "@/types/user";
import { Card, CardGroup } from "@/types/card";
import { Period } from "@/types/datepicker";
import { Bank } from "@/types/bank";
import { ProfileGetters } from "@store/modules/profile/types";
import { Role } from "@/types/role";
import { TeamGetters, TeamActions } from "@store/modules/team/types";
import { WalletGetters } from "@store/modules/wallet/types";
import { HtmlElementId } from "@/types/element";
import API from "@api";
import { MultiSelectInstance } from "@/types/components/multiselect";

enum AwaitDependency {
  CARDS = "cards",
  CARD_GROUPS = "cards-groups",
  BANKS = "banks",
}

interface Filter {
  cardIds: number[];
  bankIds: number[];
  currency: Currency[];
  groupBy: StatisticGroupField;
  periodStart: number;
  periodEnd: number;
  forceRefreshKey: number;
  cardGroupIds: (number | null)[];
  teamMembers: {
    teamleadEmails: string[];
    mediabuyerEmails: string[];
    otherEmails: string[];
  };
  selectedAll: {
    bankIds: boolean;
    cardIds: boolean;
    cardGroupIds: boolean;
    currency: boolean;
    otherEmails: boolean;
  };
}

interface StatisticItem {
  date?: string;
  group?: string;
  totalDeclinedAmount_usd: number;
  totalDeclinedAmount_eur: number;
  totalPendingAmount_usd: number;
  totalPendingAmount_eur: number;
  totalApprovedAmount_usd: number;
  totalApprovedAmount_eur: number;
  numPendingTransaction: number;
  numRefundTransaction: number;
  numApprovedTransaction: number;
  totalRefillAmount_usd: number;
  totalRefillAmount_eur: number;
  totalSpendAmount_usd: number;
  totalSpendAmount_eur: number;
  totalRefundAmount_usd: number;
  totalRefundAmount_eur: number;
  totalFeeAmount_usd: number;
  totalFeeAmount_eur: number;
  totalIssueFeeAmount_usd: number;
  totalIssueFeeAmount_eur: number;
  numDeclinedTransaction: number;
  numTransaction: number;
  userFullName: string;
  userEmail: string;
}

@Component({
  components: {
    StatisticDeclineRate: () =>
      import("../components/StatisticDeclineRate/StatisticDeclineRate.vue"),
  },
})
export default class StatisticMixin extends Vue {
  @Ref("cardGroups") private readonly cardGroupsRef?: MultiSelectInstance;
  @Ref("currencies") private readonly currenciesRef?: MultiSelectInstance;
  @Ref("banks") private readonly banksRef?: MultiSelectInstance;
  @Ref("cards") private readonly cardsRef?: MultiSelectInstance;
  @Ref("mediabuyers") private readonly mediabuyersRef?: MultiSelectInstance;
  @Ref("teamleads") private readonly teamleadsRef?: MultiSelectInstance;
  @Ref("teamMembers") private readonly teamMembersRef?: MultiSelectInstance;

  @teamModule.Action("fetchTeamMembers")
  private readonly fetchTeamMembersAction!: TeamActions["fetchTeamMembers"];
  @userModule.Action("fetchUserWebPreferences")
  private readonly fetchUserWebPreferencesAction!: UserActions["fetchUserWebPreferences"];
  @userModule.Action("updateUserWebPreferences")
  private readonly updateUserWebPreferencesAction!: UserActions["updateUserWebPreferences"];
  @userModule.Getter("userWebPreferences")
  private readonly userWebPreferencesGetter!: UserGetters["userWebPreferences"];
  @profileModule.Getter("userHasRole")
  private readonly userHasRoleGetter!: ProfileGetters["userHasRole"];
  @profileModule.Getter("profileEmail")
  private readonly profileEmailGetter!: ProfileGetters["profileEmail"];
  @profileModule.Getter("profileTeamleadEmail")
  private readonly profileTeamleadEmailGetter!: ProfileGetters["profileTeamleadEmail"];
  @teamModule.Getter("teamleadMembers")
  private readonly teamleadMembersGetter!: TeamGetters["teamleadMembers"];
  @teamModule.Getter("mediabuyerMembers")
  private readonly mediabuyerMembersGetter!: TeamGetters["mediabuyerMembers"];
  @teamModule.Getter("members")
  private readonly membersGetter!: TeamGetters["members"];
  @teamModule.Getter("membersLoading")
  private readonly membersLoadingGetter!: TeamGetters["membersLoading"];
  @walletModule.Getter("walletsCurrencies")
  private readonly walletsCurrenciesGetter!: WalletGetters["walletsCurrencies"];
  @profileModule.Getter("userIsOwner")
  private readonly userIsOwnerGetter!: ProfileGetters["userIsOwner"];

  protected loading = true;
  private isReadyStats = false;
  private cards: Card[] = [];
  private banks: Bank[] = [];
  private cardGroups: CardGroup[] = [];
  private statisticByStatus: Readonly<StatisticByStatus> | null = null;
  private statisticByType: Readonly<StatisticByType> | null = null;
  private itemsPerPage = 15;
  private readyAwaitDeps: AwaitDependency[] = [];

  private filter: Filter = {
    cardIds: [],
    currency: [],
    bankIds: [],
    cardGroupIds: [],
    groupBy: StatisticGroupField.DATE,
    periodStart: 0,
    periodEnd: 0,
    forceRefreshKey: Date.now(),
    teamMembers: {
      teamleadEmails: [],
      mediabuyerEmails: [],
      otherEmails: [],
    },
    selectedAll: {
      bankIds: false,
      cardGroupIds: false,
      cardIds: false,
      currency: false,
      otherEmails: false,
    },
  };

  private get filterPayload() {
    const { selectedAll, cardIds, cardGroupIds, bankIds, currency, ...filter } =
      this.filter;

    return {
      ...filter,
      cardIds: !selectedAll.cardIds ? cardIds : undefined,
      cardGroupIds: !selectedAll.cardGroupIds ? cardGroupIds : undefined,
      bankIds: !selectedAll.bankIds ? bankIds : undefined,
      currency: !selectedAll.currency ? currency : undefined,
    };
  }

  private get hiddenGroupFilter() {
    return false;
    return !["nat.turu.bu@gmail.com", "test@test.ru", "owner@test.ru"].includes(
      this.profileEmailGetter
    );
  }

  protected get isStatisticByStatus(): boolean {
    return this.$route.name === "statistic-by-status";
  }

  protected get isStatisticByType(): boolean {
    return this.$route.name === "statistic-by-type";
  }

  private get htmlElementId() {
    return {
      statisticDownloadFilesContainerId:
        HtmlElementId.statisticDownloadFilesContainerId,
      statisticGroupByField: HtmlElementId.statisticGroupByField,
      statisticFiltersContainerId: HtmlElementId.statisticFiltersContainerId,
    };
  }

  private get isShowTeamMemberSelect() {
    return (
      this.userHasRoleGetter(Role.ROLE_TEAMLEAD) &&
      !this.isShowTeamleadSelect &&
      !this.isShowMediabuyerSelect
    );
  }

  private get isShowTeamleadSelect() {
    return this.userHasRoleGetter([Role.ROLE_OWNER, Role.ROLE_ACCOUNTANT]);
  }

  private get isShowMediabuyerSelect() {
    return this.userHasRoleGetter([Role.ROLE_OWNER, Role.ROLE_ACCOUNTANT]);
  }

  private get isShowGroupBySelect() {
    return this.userHasRoleGetter([
      Role.ROLE_OWNER,
      Role.ROLE_TEAMLEAD,
      Role.ROLE_ACCOUNTANT,
    ]);
  }

  private get userEmails() {
    if (this.isShowTeamMemberSelect) {
      return !this.filter.selectedAll.otherEmails
        ? this.filter.teamMembers.otherEmails
        : undefined;
    }

    if (this.isShowMediabuyerSelect && this.isShowTeamleadSelect) {
      return [
        ...this.filter.teamMembers.mediabuyerEmails,
        ...this.filter.teamMembers.teamleadEmails,
      ];
    }
  }

  private get accessibleTotalIssueFeeAmount() {
    const includeUserEmails = [
      "pride2031@proton.me",
      "nat.turu.bu@gmail.com",
      "test@test.ru",
      "gdjy48@protonmail.com",
      "Telenik93@gmail.com",
      "servisesads@protonmail.com",
      "workgrinteam@gmail.com",
      "nikki_king88@proton.me",
    ];

    return (
      includeUserEmails.includes(this.profileEmailGetter) ||
      includeUserEmails.includes(this.profileTeamleadEmailGetter)
    );
  }

  private get groupByItems() {
    return [
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.statistic.groupBy.teamlead"
        ),
        value: StatisticGroupField.TEAMLEAD,
        enabled: this.userHasRoleGetter([
          Role.ROLE_OWNER,
          Role.ROLE_ACCOUNTANT,
        ]),
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.statistic.groupBy.mediabuyer"
        ),
        value: StatisticGroupField.MEDIABUYER,
        enabled: this.userHasRoleGetter([
          Role.ROLE_OWNER,
          Role.ROLE_ACCOUNTANT,
        ]),
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.statistic.groupBy.mediabuyer"
        ),
        value: StatisticGroupField.USER,
        enabled: this.userHasRoleGetter([Role.ROLE_TEAMLEAD]),
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.statistic.groupBy.date"),
        value: StatisticGroupField.DATE,
      },
    ].filter(({ enabled = true }) => enabled);
  }

  private get tableHead() {
    const pushArrayIf = <T>(rule: boolean, item: T): T[] => {
      return rule ? [item] : [];
    };

    let groupColumnText = this.$vuetify.lang.t(
      "$vuetify.dashboard.table.header.date"
    );

    if (this.filter.groupBy === StatisticGroupField.MEDIABUYER) {
      groupColumnText = this.$vuetify.lang.t("$vuetify.fields.mediabuyer");
    } else if (this.filter.groupBy === StatisticGroupField.TEAMLEAD) {
      groupColumnText = this.$vuetify.lang.t("$vuetify.fields.teamlead");
    }

    const head: {
      text: string;
      rowSpan?: number;
      colSpan?: number;
      value: string;
      align?: string;
      enabled?: boolean;
      width?: string;
      children?: {
        text: string;
        value: string;
        align?: string;
        enabled?: boolean;
      }[];
    }[] = [
      {
        text: groupColumnText,
        rowSpan: 2,
        value: "group",
        align: "start",
        enabled: !this.$vuetify.breakpoint.mobile,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.statistic_decline"
        ),
        colSpan: 2,
        align: "center",
        width: "23%",
        value: "declinedTransaction",
        children: [
          {
            text: this.$vuetify.lang.t(
              "$vuetify.dashboard.table.header.statistic_amount"
            ),
            value: "numDeclinedTransaction",
            align: "center",
            enabled: !this.$vuetify.breakpoint.mobile,
          },
          ...pushArrayIf(this.containsInFilterCurrency(Currency.USD), {
            text: this.getSymbolCurrency(Currency.USD),
            value: "totalDeclinedAmount_usd",
            align: "center",
            currency: Currency.USD,
          }),
          ...pushArrayIf(this.containsInFilterCurrency(Currency.EUR), {
            text: this.getSymbolCurrency(Currency.EUR),
            value: "totalDeclinedAmount_eur",
            align: "center",
            currency: Currency.EUR,
          }),
        ],
        enabled: this.isStatisticByStatus,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.statistic_decline_amount"
        ),
        value: "numDeclinedTransaction",
        enabled: this.$vuetify.breakpoint.mobile && this.isStatisticByStatus,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.statistic_pending"
        ),
        colSpan: 2,
        align: "center",
        width: "23%",
        value: "pendingTransaction",
        children: [
          {
            text: this.$vuetify.lang.t(
              "$vuetify.dashboard.table.header.statistic_amount"
            ),
            value: "numPendingTransaction",
            align: "center",
            enabled: !this.$vuetify.breakpoint.mobile,
          },
          ...pushArrayIf(this.containsInFilterCurrency(Currency.USD), {
            text: this.getSymbolCurrency(Currency.USD),
            value: "totalPendingAmount_usd",
            align: "center",
            currency: Currency.USD,
          }),
          ...pushArrayIf(this.containsInFilterCurrency(Currency.EUR), {
            text: this.getSymbolCurrency(Currency.EUR),
            value: "totalPendingAmount_eur",
            align: "center",
            currency: Currency.EUR,
          }),
        ],
        enabled: this.isStatisticByStatus,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.statistic_pending_amount"
        ),
        value: "numPendingTransaction",
        enabled: this.$vuetify.breakpoint.mobile && this.isStatisticByStatus,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.statistic_approved"
        ),
        colSpan: 2,
        align: "center",
        width: "23%",
        value: "approvedTransaction",
        children: [
          {
            text: this.$vuetify.lang.t(
              "$vuetify.dashboard.table.header.statistic_amount"
            ),
            value: "numApprovedTransaction",
            align: "center",
            enabled: !this.$vuetify.breakpoint.mobile,
          },
          ...pushArrayIf(this.containsInFilterCurrency(Currency.USD), {
            text: this.getSymbolCurrency(Currency.USD),
            value: "totalApprovedAmount_usd",
            align: "center",
            currency: Currency.USD,
          }),
          ...pushArrayIf(this.containsInFilterCurrency(Currency.EUR), {
            text: this.getSymbolCurrency(Currency.EUR),
            value: "totalApprovedAmount_eur",
            align: "center",
            currency: Currency.EUR,
          }),
        ],
        enabled: this.isStatisticByStatus,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.statistic_approved_amount"
        ),
        value: "numApprovedTransaction",
        enabled: this.$vuetify.breakpoint.mobile && this.isStatisticByStatus,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.statistic_refunded"
        ),
        colSpan: 2,
        align: "center",
        width: "23%",
        value: "refundTransaction",
        children: [
          {
            text: this.$vuetify.lang.t(
              "$vuetify.dashboard.table.header.statistic_amount"
            ),
            value: "numRefundTransaction",
            align: "center",
            enabled: !this.$vuetify.breakpoint.mobile,
          },
          ...pushArrayIf(this.containsInFilterCurrency(Currency.USD), {
            text: this.getSymbolCurrency(Currency.USD),
            value: "totalRefundAmount_usd",
            align: "center",
            currency: Currency.USD,
          }),
          ...pushArrayIf(this.containsInFilterCurrency(Currency.EUR), {
            text: this.getSymbolCurrency(Currency.EUR),
            value: "totalRefundAmount_eur",
            align: "center",
            currency: Currency.EUR,
          }),
        ],
        enabled: this.isStatisticByStatus,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.statistic_refunded_amount"
        ),
        value: "numRefundTransaction",
        enabled: this.$vuetify.breakpoint.mobile && this.isStatisticByStatus,
      },
      {
        // text: this.$vuetify.lang.t(
        //   "$vuetify.dashboard.table.header.transactions_amount"
        // ),
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.transactions"
        ),
        colSpan: 2,
        align: "center",
        width: "16%",
        value: "transaction",
        children: [
          {
            text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.total"),
            value: "numTransaction",
            align: "center",
          },
          {
            text: this.$vuetify.lang.t(
              "$vuetify.dashboard.table.header.declined"
            ),
            value: "numDeclinedTransaction",
            align: "center",
          },
        ],
        enabled: !this.$vuetify.breakpoint.mobile && this.isStatisticByType,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.payments"),
        colSpan: 2,
        align: "center",
        width: "16%",
        value: "spendAmount",
        children: [
          ...pushArrayIf(this.containsInFilterCurrency(Currency.USD), {
            text: this.getSymbolCurrency(Currency.USD),
            value: "totalSpendAmount_usd",
            align: "center",
            currency: Currency.USD,
          }),
          ...pushArrayIf(this.containsInFilterCurrency(Currency.EUR), {
            text: this.getSymbolCurrency(Currency.EUR),
            value: "totalSpendAmount_eur",
            align: "center",
            currency: Currency.EUR,
          }),
        ],
        enabled: this.isStatisticByType,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.income"),
        colSpan: 2,
        align: "center",
        width: "16%",
        value: "refillAmount",
        children: [
          ...pushArrayIf(this.containsInFilterCurrency(Currency.USD), {
            text: this.getSymbolCurrency(Currency.USD),
            value: "totalRefillAmount_usd",
            align: "center",
            currency: Currency.USD,
          }),
          ...pushArrayIf(this.containsInFilterCurrency(Currency.EUR), {
            text: this.getSymbolCurrency(Currency.EUR),
            value: "totalRefillAmount_eur",
            align: "center",
            currency: Currency.EUR,
          }),
        ],
        enabled: this.isStatisticByType,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.refunds"),
        colSpan: 2,
        align: "center",
        width: "16%",
        value: "refundAmount",
        children: [
          ...pushArrayIf(this.containsInFilterCurrency(Currency.USD), {
            text: this.getSymbolCurrency(Currency.USD),
            value: "totalRefundAmount_usd",
            align: "center",
            currency: Currency.USD,
          }),
          ...pushArrayIf(this.containsInFilterCurrency(Currency.EUR), {
            text: this.getSymbolCurrency(Currency.EUR),
            value: "totalRefundAmount_eur",
            align: "center",
            currency: Currency.EUR,
          }),
        ],
        enabled: this.isStatisticByType,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.commission_rate"
        ),
        colSpan: 2,
        align: "center",
        width: "16%",
        value: "feeAmount",
        children: [
          ...pushArrayIf(this.containsInFilterCurrency(Currency.USD), {
            text: this.getSymbolCurrency(Currency.USD),
            value: "totalFeeAmount_usd",
            align: "center",
            currency: Currency.USD,
          }),
          ...pushArrayIf(this.containsInFilterCurrency(Currency.EUR), {
            text: this.getSymbolCurrency(Currency.EUR),
            value: "totalFeeAmount_eur",
            align: "center",
            currency: Currency.EUR,
          }),
        ],
        enabled: this.isStatisticByType,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.commission_rate_for_cards"
        ),
        colSpan: 2,
        align: "center",
        width: "16%",
        value: "totalIssueFeeAmount",
        children: [
          ...pushArrayIf(this.containsInFilterCurrency(Currency.USD), {
            text: this.getSymbolCurrency(Currency.USD),
            value: "totalIssueFeeAmount_usd",
            align: "center",
            currency: Currency.USD,
          }),
          ...pushArrayIf(this.containsInFilterCurrency(Currency.EUR), {
            text: this.getSymbolCurrency(Currency.EUR),
            value: "totalIssueFeeAmount_eur",
            align: "center",
            currency: Currency.EUR,
          }),
        ],
        enabled: this.isStatisticByType && this.accessibleTotalIssueFeeAmount,
      },
      {
        text: `${this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.transactions"
        )} (${this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.declined"
        )})`,
        value: "numDeclinedTransaction",
        enabled: this.$vuetify.breakpoint.mobile && this.isStatisticByType,
      },
      {
        text: `${this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.transactions"
        )} (${this.$vuetify.lang.t("$vuetify.dashboard.table.header.total")})`,
        value: "numTransaction",
        enabled: this.$vuetify.breakpoint.mobile && this.isStatisticByType,
      },
    ];

    return head.reduce<typeof head>((acc, header) => {
      const headerEnabled = header.enabled ?? true;

      if (!headerEnabled) {
        return acc;
      }

      acc.push({
        ...header,
        children: header.children?.filter(({ enabled = true }) => enabled),
      });

      return acc;
    }, []);
  }

  protected get disabledSearch(): boolean {
    const rangeDay = Math.ceil(
      (this.filter.periodEnd - this.filter.periodStart) / (60 * 60 * 24)
    );

    return !rangeDay || rangeDay < 0 || rangeDay > 7 * 30;
  }

  private get rangeDate() {
    const range: string[] = [];

    if (this.filter.periodStart) {
      range.push(formatDatePickerVal(this.filter.periodStart * 1000));
    }

    if (this.filter.periodEnd) {
      range.push(formatDatePickerVal(this.filter.periodEnd * 1000));
    }

    return range;
  }

  private set rangeDate(val) {
    const dateStart = val[0] ? parseDatePickerVal(val[0]) : null;
    const dateEnd = val[1] ? parseDatePickerVal(val[1]) : null;

    if (dateStart) {
      this.filter.periodStart = periodRangeStartTimestamp(dateStart.getTime());
    } else {
      this.filter.periodStart = 0;
    }

    if (dateEnd) {
      this.filter.periodEnd = periodRangeEndTimestamp(dateEnd);
    } else {
      this.filter.periodEnd = 0;
    }
  }

  private get headers() {
    return this.tableHead.reduce<DataTableHeader[]>((acc, item) => {
      if (Array.isArray(item.children)) {
        item.children.forEach(({ value, text, align }) =>
          acc.push({
            value,
            text: `${item.text} (${text})`,
            align: align as DataTableHeader["align"],
          })
        );
      } else if (item.value) {
        acc.push({
          text: item.text,
          value: item.value,
          align: item.align as DataTableHeader["align"],
        });
      }

      return acc;
    }, []);
  }

  private get items() {
    let items: StatisticItem[] = [];

    if (this.isStatisticByStatus && this.statisticByStatus) {
      items = Object.values(
        Object.entries(this.statisticByStatus.data).reduce<
          Record<string, StatisticItem>
        >((acc, [currencyVal, statistic]) => {
          if (!statistic) {
            return acc;
          }

          const currency = currencyVal as Currency;

          statistic.forEach((item) => {
            const itemKey = item.group;
            let accItem = acc[itemKey];

            if (!accItem) {
              acc[itemKey] = {
                date: this.formatDate(item.date),
                userFullName: getFullName({
                  firstName: item.profile?.firstName,
                  lastName: item.profile?.lastName,
                }),
                userEmail: item.profile?.email || "",
                group: item.group,
                totalDeclinedAmount_usd: 0,
                totalPendingAmount_usd: 0,
                totalRefundAmount_usd: 0,
                totalApprovedAmount_usd: 0,
                totalRefillAmount_usd: 0,
                totalSpendAmount_usd: 0,
                totalFeeAmount_usd: 0,
                totalIssueFeeAmount_usd: 0,
                totalDeclinedAmount_eur: 0,
                totalPendingAmount_eur: 0,
                totalRefundAmount_eur: 0,
                totalApprovedAmount_eur: 0,
                totalRefillAmount_eur: 0,
                totalSpendAmount_eur: 0,
                totalFeeAmount_eur: 0,
                totalIssueFeeAmount_eur: 0,
                numDeclinedTransaction: 0,
                numPendingTransaction: 0,
                numRefundTransaction: 0,
                numApprovedTransaction: 0,
                numTransaction: 0,
              };

              accItem = acc[itemKey];
            }

            const totalPendingAmount = numberToFixed(
              item.totalPendingAmount,
              2
            );
            const totalRefundAmount = numberToFixed(item.totalRefundAmount, 2);
            const totalApprovedAmount = numberToFixed(
              item.totalApprovedAmount,
              2
            );
            const totalDeclinedAmount = numberToFixed(
              item.totalDeclinedAmount,
              2
            );

            switch (currency) {
              case Currency.EUR:
                accItem.totalPendingAmount_eur = totalPendingAmount;
                accItem.totalRefundAmount_eur = totalRefundAmount;
                accItem.totalApprovedAmount_eur = totalApprovedAmount;
                accItem.totalDeclinedAmount_eur = totalDeclinedAmount;
                break;

              case Currency.USD:
                accItem.totalPendingAmount_usd = totalPendingAmount;
                accItem.totalRefundAmount_usd = totalRefundAmount;
                accItem.totalApprovedAmount_usd = totalApprovedAmount;
                accItem.totalDeclinedAmount_usd = totalDeclinedAmount;
                break;

              default:
                break;
            }

            if (this.containsInFilterCurrency(currency)) {
              accItem.numDeclinedTransaction += numberToFixed(
                item.numDeclinedTransaction,
                2
              );

              accItem.numPendingTransaction += numberToFixed(
                item.numPendingTransaction,
                2
              );

              accItem.numRefundTransaction += numberToFixed(
                item.numRefundTransaction,
                2
              );

              accItem.numApprovedTransaction += numberToFixed(
                item.numApprovedTransaction,
                2
              );
            }
          });

          return acc;
        }, {})
      );
    } else if (this.isStatisticByType && this.statisticByType) {
      items = Object.values(
        Object.entries(this.statisticByType.data).reduce<
          Record<string, StatisticItem>
        >((acc, [currencyVal, statistic]) => {
          if (!statistic) {
            return acc;
          }

          const currency = currencyVal as Currency;

          statistic.forEach((item) => {
            const itemKey = item.group;
            let accItem = acc[itemKey];

            if (!accItem) {
              acc[itemKey] = {
                date: this.formatDate(item.date),
                userFullName: getFullName({
                  firstName: item.profile?.firstName,
                  lastName: item.profile?.lastName,
                }),
                userEmail: item.profile?.email || "",
                group: item.group,
                totalDeclinedAmount_usd: 0,
                totalPendingAmount_usd: 0,
                totalRefundAmount_usd: 0,
                totalApprovedAmount_usd: 0,
                totalRefillAmount_usd: 0,
                totalSpendAmount_usd: 0,
                totalFeeAmount_usd: 0,
                totalIssueFeeAmount_usd: 0,
                totalDeclinedAmount_eur: 0,
                totalPendingAmount_eur: 0,
                totalRefundAmount_eur: 0,
                totalApprovedAmount_eur: 0,
                totalRefillAmount_eur: 0,
                totalSpendAmount_eur: 0,
                totalFeeAmount_eur: 0,
                totalIssueFeeAmount_eur: 0,
                numDeclinedTransaction: 0,
                numPendingTransaction: 0,
                numRefundTransaction: 0,
                numApprovedTransaction: 0,
                numTransaction: 0,
              };

              accItem = acc[itemKey];
            }

            const totalSpendAmount = numberToFixed(item.totalSpendAmount, 2);
            const totalRefundAmount = numberToFixed(item.totalRefundAmount, 2);
            const totalIssueFeeAmount = numberToFixed(
              item.totalIssueFeeAmount,
              2
            );
            const totalFeeAmount = this.accessibleTotalIssueFeeAmount
              ? numberToFixed(item.totalCommissionFeeAmount, 2)
              : numberToFixed(item.totalFeeAmount, 2);
            const totalRefillAmount = numberToFixed(item.totalRefillAmount, 2);

            switch (currency) {
              case Currency.EUR:
                accItem.totalSpendAmount_eur = totalSpendAmount;
                accItem.totalRefundAmount_eur = totalRefundAmount;
                accItem.totalFeeAmount_eur = totalFeeAmount;
                accItem.totalIssueFeeAmount_eur = totalIssueFeeAmount;
                accItem.totalRefillAmount_eur = totalRefillAmount;
                break;

              case Currency.USD:
                accItem.totalSpendAmount_usd = totalSpendAmount;
                accItem.totalRefundAmount_usd = totalRefundAmount;
                accItem.totalFeeAmount_usd = totalFeeAmount;
                accItem.totalIssueFeeAmount_usd = totalIssueFeeAmount;
                accItem.totalRefillAmount_usd = totalRefillAmount;
                break;

              default:
                break;
            }

            if (this.containsInFilterCurrency(currency)) {
              accItem.numDeclinedTransaction += numberToFixed(
                item.numDeclinedTransaction,
                2
              );

              accItem.numTransaction += numberToFixed(
                item.numTransaction +
                  item.numDeclinedTransaction +
                  item.numAuthorizationTransaction,
                2
              );
            }
          });

          return acc;
        }, {})
      );
    }

    items?.sort((a, b) => {
      if (b.date && a.date) {
        const formatDate = (v: string) => v.split(".").reverse().join("-");

        return (
          new Date(formatDate(b.date)).getTime() -
          new Date(formatDate(a.date)).getTime()
        );
      }

      if (b.group && a.group) {
        if (b.group.toLowerCase() > a.group.toLowerCase()) {
          return -1;
        }

        if (a.group.toLowerCase() > b.group.toLowerCase()) {
          return 1;
        }
      }

      return 0;
    });

    return items || [];
  }

  private get totalItem() {
    return this.items.reduce<StatisticItem>(
      (acc, item) => {
        forOwn(item, (value, property) => {
          if (
            property === "date" ||
            property === "group" ||
            property === "userFullName" ||
            property === "userEmail" ||
            typeof value !== "number"
          ) {
            return;
          }

          acc[property] = numberToFixed(acc[property] + item[property], 2);

          item[property] = value;
        });

        return acc;
      },
      {
        group: this.$vuetify.lang.t("$vuetify.dashboard.table.header.total"),
        totalDeclinedAmount_usd: 0,
        totalPendingAmount_usd: 0,
        totalRefundAmount_usd: 0,
        totalApprovedAmount_usd: 0,
        totalRefillAmount_usd: 0,
        totalSpendAmount_usd: 0,
        totalFeeAmount_usd: 0,
        totalIssueFeeAmount_usd: 0,
        totalDeclinedAmount_eur: 0,
        totalPendingAmount_eur: 0,
        totalRefundAmount_eur: 0,
        totalApprovedAmount_eur: 0,
        totalRefillAmount_eur: 0,
        totalSpendAmount_eur: 0,
        totalFeeAmount_eur: 0,
        totalIssueFeeAmount_eur: 0,
        numDeclinedTransaction: 0,
        numPendingTransaction: 0,
        numRefundTransaction: 0,
        numApprovedTransaction: 0,
        numTransaction: 0,
        userFullName: "",
        userEmail: "",
      }
    );
  }

  private getDownloadedData() {
    const headers = this.headers.reduce<Record<string, string>>(
      (acc, { value, text }) => {
        acc[value] = text;

        return acc;
      },
      {}
    );

    return {
      items: this.items
        .map<StatisticItem>((item) => ({
          ...item,
          group:
            item.userFullName && item.userEmail
              ? `${item.userFullName} (${item.userEmail})`
              : item.group,
        }))
        .concat(this.totalItem),
      headers,
    };
  }

  private containsInFilterCurrency(currency: Currency) {
    return (
      !this.filter.currency.length || this.filter.currency.includes(currency)
    );
  }

  private hashStringToColor(str: string) {
    return hashStringToColor(str);
  }

  private getCurrencyIcon(currency: Currency) {
    return require(`@/assets/img/currency/flags/${currency}.svg`);
  }

  private getSymbolCurrency(currency: Currency) {
    return getSymbolCurrency(currency);
  }

  private onReadyCards(items: Card[]) {
    this.cards = items;

    this.onReadyAwaitDeps(AwaitDependency.CARDS);
  }

  private onReadyBanks(items: Bank[]) {
    this.banks = items;

    this.onReadyAwaitDeps(AwaitDependency.BANKS);
  }

  private onReadyCardGroups(items: CardGroup[]) {
    this.cardGroups = items;

    this.onReadyAwaitDeps(AwaitDependency.CARD_GROUPS);
  }

  private onReadyAwaitDeps(key: AwaitDependency) {
    this.readyAwaitDeps = this.readyAwaitDeps.filter(
      (dependencyKey) => dependencyKey !== key
    );
  }

  private downloadCSV() {
    downloadCSV(this.getDownloadedData());
  }

  private downloadXLSX() {
    downloadXLSX(this.getDownloadedData());
  }

  protected async fetchAllStats() {
    this.teamMembersRef?.selectAll();
    this.teamleadsRef?.selectAll();
    this.cardGroupsRef?.selectAll();
    this.cardsRef?.selectAll();
    this.currenciesRef?.selectAll();
    this.banksRef?.selectAll();

    if (this.teamleadsRef) {
      this.filter.teamMembers.mediabuyerEmails =
        this.mediabuyerMembersGetter.map(({ email }) => email);
    }

    await this.$nextTick();

    this.refreshStats();
  }

  protected refreshStats(): void {
    if (!this.filter.teamMembers.otherEmails.length) {
      this.filter.teamMembers.otherEmails = [this.profileEmailGetter];
    }

    if (
      !this.filter.teamMembers.teamleadEmails.length &&
      !this.filter.teamMembers.mediabuyerEmails.length
    ) {
      this.filter.teamMembers.teamleadEmails = this.teamleadMembersGetter
        .map(({ email }) => email)
        .concat([this.profileEmailGetter]);

      this.filter.teamMembers.mediabuyerEmails =
        this.mediabuyerMembersGetter.map(({ email }) => email);
    }

    this.filter.cardIds = !this.filter.cardIds?.length
      ? this.cards.map(({ id }) => id)
      : this.filter.cardIds;

    this.filter.bankIds = !this.filter.bankIds?.length
      ? this.banks.map(({ id }) => id)
      : this.filter.bankIds;

    this.filter.cardGroupIds = !this.filter.cardGroupIds?.length
      ? this.cardGroups.map(({ id }) => id)
      : this.filter.cardGroupIds;

    this.filter.currency = !this.filter.currency?.length
      ? this.walletsCurrenciesGetter.map((value) => value)
      : this.filter.currency;

    this.filter.forceRefreshKey++;
  }

  private formatDate(val?: string) {
    return val?.split("-").reverse().join(".");
  }

  private formatMoney(options: { value: number; currency: Currency }) {
    return formatMoney(options);
  }

  private async searchStatistic({
    cancelToken,
    periodStart,
    periodEnd,
    cardIds,
    bankIds,
    groupBy,
    ...payload
  }: Omit<
    Filter,
    | "forceRefreshKey"
    | "selectedAll"
    | "cardIds"
    | "bankIds"
    | "cardGroupIds"
    | "currency"
  > &
    Partial<
      Pick<Filter, "cardIds" | "bankIds" | "cardGroupIds" | "currency">
    > & {
      cancelToken?: CancelToken;
    }) {
    this.loading = true;

    let userEmails: string[] | undefined;

    let teamleadEmails =
      this.filter.teamMembers.teamleadEmails.length &&
      this.filter.groupBy !== StatisticGroupField.MEDIABUYER &&
      this.isShowTeamleadSelect
        ? this.filter.teamMembers.teamleadEmails
        : undefined;

    const cardGroupIds =
      !this.hiddenGroupFilter && payload.cardGroupIds?.length
        ? payload.cardGroupIds
        : undefined;

    if (this.isShowTeamMemberSelect) {
      userEmails = this.userEmails;
    } else {
      userEmails = teamleadEmails
        ? [...teamleadEmails, ...this.filter.teamMembers.mediabuyerEmails]
        : this.filter.teamMembers.mediabuyerEmails;
    }

    userEmails = userEmails?.length ? userEmails : undefined;

    userEmails = teamleadEmails?.includes(this.profileEmailGetter)
      ? userEmails?.concat(this.profileEmailGetter)
      : userEmails;

    teamleadEmails = !this.userHasRoleGetter(Role.ROLE_TEAMLEAD)
      ? teamleadEmails?.filter((email) => email !== this.profileEmailGetter)
      : teamleadEmails;

    userEmails = userEmails?.length ? [...new Set(userEmails)] : undefined;

    try {
      if (this.isStatisticByStatus) {
        const statistic = await API.statistic.searchStatisticByStatus({
          cancelToken,
          periodStart,
          periodEnd,
          cardIds,
          bankIds,
          groupBy,
          // teamleadEmails,
          userEmails,
          cardGroupIds,
        });

        Object.freeze(statistic.data);
        Object.freeze(statistic.summary);

        this.statisticByStatus = statistic;
      } else {
        const statistic = await API.statistic.searchStatisticByType({
          cancelToken,
          periodStart,
          periodEnd,
          cardIds,
          bankIds,
          groupBy,
          // teamleadEmails,
          userEmails,
          cardGroupIds,
        });

        Object.freeze(statistic.data);

        this.statisticByType = statistic;
      }

      this.loading = false;
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.statisticByStatus = null;
        this.statisticByType = null;
        this.loading = false;
      }
    }
  }

  private async initWebPreferences() {
    const userWebPreferencesKey = this.isStatisticByStatus
      ? UserWebPreferencesKey.STATISTIC_BY_STATUS
      : UserWebPreferencesKey.STATISTIC;

    this.loading = true;

    await this.fetchUserWebPreferencesAction({
      key: userWebPreferencesKey,
    });

    this.loading = false;

    const { value } = this.userWebPreferencesGetter({
      key: userWebPreferencesKey,
    });

    const userWebPreferences = value as Partial<{
      showedTableHeaders: string[];
      filter: Partial<Filter>;
      itemsPerPage: number;
    }>;

    this.itemsPerPage = userWebPreferences.itemsPerPage ?? this.itemsPerPage;

    const filterCardIdFromQuery = +this.$route.query.filterCardId;
    const filterCardOwnerEmail = this.$route.query.filterCardOwnerEmail;

    if (
      Number.isInteger(filterCardIdFromQuery) &&
      typeof filterCardOwnerEmail === "string"
    ) {
      this.filter.cardIds = [filterCardIdFromQuery];

      if (this.isShowTeamMemberSelect) {
        this.filter.teamMembers.otherEmails = [filterCardOwnerEmail];
      } else {
        this.filter.teamMembers.teamleadEmails =
          this.teamleadMembersGetter.some(
            ({ email }) => email === filterCardOwnerEmail
          )
            ? [filterCardOwnerEmail]
            : this.filter.teamMembers.teamleadEmails;

        this.filter.teamMembers.mediabuyerEmails =
          this.mediabuyerMembersGetter.some(
            ({ email }) => email === filterCardOwnerEmail
          )
            ? [filterCardOwnerEmail]
            : this.filter.teamMembers.mediabuyerEmails;
      }
    } else {
      const teamleadOwnersFromStorage =
        userWebPreferences?.filter?.teamMembers?.teamleadEmails?.filter(
          (teamleadEmail) => {
            if (this.userIsOwnerGetter) {
              return (
                this.profileEmailGetter === teamleadEmail ||
                this.teamleadMembersGetter.some(
                  ({ email }) => email === teamleadEmail
                )
              );
            }

            return this.teamleadMembersGetter.some(
              ({ email }) => email === teamleadEmail
            );
          }
        );

      this.filter.teamMembers.teamleadEmails = teamleadOwnersFromStorage?.length
        ? teamleadOwnersFromStorage
        : [];

      const mediabuyerOwnersFromStorage =
        userWebPreferences?.filter?.teamMembers?.mediabuyerEmails?.filter(
          (mediabuyerEmail) =>
            this.mediabuyerMembersGetter.some(
              ({ email }) => email === mediabuyerEmail
            )
        );

      this.filter.teamMembers.mediabuyerEmails =
        mediabuyerOwnersFromStorage?.length ? mediabuyerOwnersFromStorage : [];

      const otherOwnersFromStorage =
        userWebPreferences?.filter?.teamMembers?.otherEmails?.filter(
          (otherEmail) =>
            otherEmail === this.profileEmailGetter ||
            this.membersGetter.some(({ email }) => email === otherEmail)
        );

      this.filter.teamMembers.otherEmails = otherOwnersFromStorage?.length
        ? otherOwnersFromStorage
        : [];

      this.filter.cardIds = userWebPreferences?.filter?.cardIds?.length
        ? userWebPreferences.filter.cardIds
        : this.filter.cardIds;
    }

    const cardGroupsFromStorage =
      userWebPreferences.filter?.cardGroupIds?.filter((id) =>
        this.cardGroups.some((cardGroup) => cardGroup.id === id)
      );

    this.filter.cardGroupIds = cardGroupsFromStorage?.length
      ? cardGroupsFromStorage
      : [];

    this.filter.bankIds = userWebPreferences.filter?.bankIds?.length
      ? userWebPreferences.filter.bankIds
      : [];

    this.filter.currency = userWebPreferences.filter?.currency?.length
      ? userWebPreferences.filter.currency
      : [];

    if (userWebPreferences.filter?.groupBy) {
      this.filter.groupBy =
        this.groupByItems.find(
          ({ value }) => value === userWebPreferences.filter?.groupBy
        )?.value || this.groupByItems[0].value;
    }

    this.refreshStats();

    return this.$watch(
      () => {
        return [
          this.itemsPerPage,
          this.filter.cardIds.length,
          this.filter.teamMembers.mediabuyerEmails.length,
          this.filter.teamMembers.teamleadEmails.length,
          this.filter.teamMembers.otherEmails.length,
          this.filter.bankIds.length,
          this.filter.cardGroupIds.length,
          this.filter.currency.length,
          this.filter.groupBy,
        ].join("-");
      },
      debounce(() => {
        const {
          cardIds,
          groupBy,
          bankIds,
          currency,
          teamMembers,
          cardGroupIds,
        } = this.filter;

        this.updateUserWebPreferencesAction({
          key: userWebPreferencesKey,
          value: {
            filter: {
              teamMembers,
              cardIds,
              groupBy,
              bankIds,
              currency,
              cardGroupIds,
            },
            itemsPerPage: this.itemsPerPage,
          },
        });
      }, 500)
    );
  }

  private initWatcherSearchStatistic() {
    let cancelTokenSource: CancelTokenSource | null = null;

    this.loading = true;

    this.$watch(
      () => {
        return [
          // this.filter.periodStart,
          // this.filter.periodEnd,
          // this.filter.cardIds.length,
          this.filterPayload.groupBy,
          this.filterPayload.forceRefreshKey,
        ].join("-");
      },
      () => {
        if (!this.filterPayload.periodStart || !this.filterPayload.periodEnd)
          return;

        cancelTokenSource?.cancel();
        cancelTokenSource = axios.CancelToken.source();
        cancelTokenSource.token;

        this.searchStatistic({
          ...this.filterPayload,
          cancelToken: cancelTokenSource.token,
          periodStart: this.filter.periodStart + getTimezoneHoursInSeconds(),
          periodEnd: this.filter.periodEnd + getTimezoneHoursInSeconds(),
        });
      }
    );

    this.refreshStats();

    this.$once("hook:beforeDestroy", () => {
      cancelTokenSource?.cancel();
    });
  }

  private async initStats() {
    const { start: periodStart, end: periodEnd } = getPeriodDate(
      Period.LAST_MONTH
    );

    this.filter.periodStart = periodRangeStartTimestamp(periodStart);
    this.filter.periodEnd = periodRangeEndTimestamp(periodEnd);

    await Promise.all([this.fetchTeamMembersAction()]);

    await this.initWebPreferences();

    this.initWatcherSearchStatistic();

    this.isReadyStats = true;
  }

  private created() {
    this.readyAwaitDeps = [AwaitDependency.BANKS, AwaitDependency.CARDS];

    if (!this.hiddenGroupFilter) {
      this.readyAwaitDeps.push(AwaitDependency.CARD_GROUPS);
    }
  }

  private mounted() {
    this.$watch(
      () => {
        return (
          this.readyAwaitDeps.length === 0 &&
          this.walletsCurrenciesGetter.length > 0
        );
      },
      (isReady) => {
        if (!isReady) return;

        this.initStats();
      },
      { immediate: true }
    );
  }
}
